import { Flex, useToasts, Button } from '@applyboard/crystal-ui'
import { useNavigate } from 'react-router-dom'
import { formatErrorMessage } from 'src/utils'
import { CreateProfileProps } from '../../clients'
import { NavBars } from '../../components/NavBars'
import { PageHeading } from '../../components/PageHeading'
import { ProfileForm } from '../../components/ProfileForm'
import { useProfile } from '../../hooks'
import { useCreateProfile } from '../../hooks/profile/useCreateProfile'
import { LoadingPage } from '../LoadingPage'

export function AddProfilePage(): JSX.Element {
  const { createProfile, isCreatingProfile } = useCreateProfile()
  const navigate = useNavigate()
  const toasts = useToasts()
  const {
    profileData: currentSessionUser,
    isGetProfileLoading: isGetCurrentSessionUserLoading,
  } = useProfile()
  const currentSessionUserIsResolved =
    !isGetCurrentSessionUserLoading && currentSessionUser

  if (!currentSessionUserIsResolved) {
    return <LoadingPage />
  }

  const handleSubmit = (profileData: CreateProfileProps) => {
    createProfile(profileData, {
      onSuccess: () => {
        navigate('/profiles')
        toasts.positive('Success: Added user.')
      },
      onError: (error) => {
        const formattedError = formatErrorMessage(error)
        toasts.negative(formattedError)
      },
    })
  }

  const throwError = () => {
    throw new Error('This is an error');
  }

  return (
    <NavBars selectedMenuItem="Create User">
      <Button onClick={throwError}>This throws an error</Button>
      <Flex p={{ xs: 4, sm: 12 }} direction="column">
        <PageHeading
          title="Add User"
          imageSrc="/user-management-icon.svg"
          imageAlt="add user icon"
        />
        <ProfileForm
          currentSessionUser={currentSessionUser}
          onSubmit={handleSubmit}
          isLoading={isCreatingProfile}
        />
      </Flex>
    </NavBars>
  )
}
